<template>
  <!-- power权限管理 -->
  <div id="Power">
    <!-- Dialog 对话框 -->
    <el-dialog :title="openType === 'add' ? '添加权限' : '编辑权限'"
               :visible.sync="isShowDialogVisible"
               :show-close="false">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm(openType)">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="addPowerForm()"
                     icon="el-icon-circle-plus-outline">添加</el-button>
          <!-- <el-button type="danger" size="small" icon="el-icon-delete" @click="delPowerGroup()">删除</el-button> -->
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="formLabel"
                    @blurInput="blurInput"
                    inline>
          <el-button type="primary"
                     @click="searchFormName(searchForm.keyword)">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <el-row v-for="item in tableData"
                :key="item.id">
          <el-button type="text"
                     disabled>{{ item.parent }}</el-button>
          <el-button type="text">{{ item.name }}</el-button>
          <el-divider content-position="left">权限</el-divider>
          <el-row>
            <el-col v-for="citem in item.permission"
                    :key="citem.id">
              <el-button type="success"
                         size="mini"
                         @click="editPower(citem.id)">{{ citem.name }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { metMenuList, getPowerData, methPowerData } from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,

      // form表单 基础数据
      openForm: {
        id: '',
        name: '',
        description: '',
        module: '',
        controller: '',
        action: '',
        behavior: '',
        permission_group_id: ''
      },
      openFormLabel: [
        {
          model: 'name',
          label: '权限名称'
        },
        {
          model: 'description',
          label: '备注'
        },
        {
          model: 'module',
          label: '模块'
        },
        {
          model: 'controller',
          label: '控制器'
        },
        {
          model: 'action',
          label: '方法名'
        },
        {
          model: 'behavior',
          label: '标识code'
        },
        {
          model: 'permission_group_id',
          label: '关联组'
        }
      ],

      // 搜索
      searchForm: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: '名字',
          labelOFF: true,
          options: []
        }
      ],
      // 表格数据
      tableData: [],

      // 权限组列表
      MenuListData: [],
      // 权限列表
      PowerListData: []
    }
  },
  mounted () {
    this.getPowerData()
  },
  watch: {
    PowerListData: function (newVal, oldVal) {
      this.getPowerGroupList()
    }
  },
  methods: {
    // 获取权限组列表
    getPowerGroupList () {
      metMenuList().then(res => {
        this.MenuListData = res.data.data
        this.formPowerList()
      })
    },

    // 获取权限数据
    getPowerData () {
      getPowerData().then(res => {
        const Data = res.data
        if (Data.code == 200) {
          this.$message({
            message: Data.message,
            type: 'success'
          })
          this.PowerListData = Data.data.list
          console.log('执行成功')
        } else {
          this.$message({
            message: Data.message,
            type: 'error'
          })
        }
      })
    },
    // 权限操作（增加，修改）
    methPowerData (method, args) {
      methPowerData(method, args).then(res => {
        console.log(res)
        if ((res.data.code = 200)) {
          this.getPowerData()
        }
      })
    },

    // 数据格式化渲染
    formPowerList () {
      // 格式
      // [
      //   {
      //     id:3,
      //     name:'角色列表',
      //     parent_id:5,
      //     parent:'组织架构',
      //     code: "jieseliebiao",
      //     permission: [
      //       {
      //           "id": 3,
      //           "name": "权限组列表",
      //           "description": "权限组列表",
      //           "behavior": "list",
      //           "permission_group_id": 3
      //       },
      //     ]
      //   }
      // ]

      // 权限-所属组的id
      const powerlist = [
        ...new Set(this.PowerListData.map(item => item.permission_group_id))
      ]

      let itemMenuListData = []

      for (let i = 0; i < powerlist.length; i++) {
        this.MenuListData.map(item => {
          itemMenuListData = item.child.filter(mItem => {
            if (powerlist.includes(mItem.id)) {
              if (item.id == mItem.parent_id) {
                const permission = this.PowerListData.filter(pItem => {
                  if (mItem.id == pItem.permission_group_id) {
                    return pItem
                  }
                })
                mItem.parent = item.name
                mItem.permission = permission
              }
              return mItem
            }
          })
        })
      }
      // 表格所以数据
      this.tableData = itemMenuListData
    },

    // 失去焦点执行
    blurInput (val) {
      if (!val) {
        // 渲染table 数据
        this.powerTableData()
      }
    },

    // 添加按钮
    addPowerForm () {
      this.isShowDialogVisible = true
    },

    // 取消提交按钮
    cancelPowerForm (type) {
      console.log(type)
      type == 'add' ? '' : this.openFormLabel.shift()
      this.isShowDialogVisible = false
    },
    // 确定提交按钮
    subPowerForm (type, val) {
      type == 'add'
        ? this.methPowerData('POST', val)
        : this.methPowerData('PUT', val)
      this.cancelPowerForm()
    },
    // 编辑操作按钮
    editPower (id) {
      const ID = {
        model: 'id',
        label: 'ID'
      }
      this.openType = 'edit'
      this.openFormLabel.unshift(ID)

      const pIdata = this.PowerListData.filter(item => {
        if (item.id == id) return item
      })
      this.openForm = pIdata[0]
      this.isShowDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
#Power {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
}
.el-row {
  margin: 10px !important;
  box-sizing: border-box;
  .el-divider {
    margin: 16px 0;
  }
  .el-col {
    display: inline-block;
    margin: 10px;
  }
  .el-col.el-col-24 {
    width: auto;
  }
}
</style>
